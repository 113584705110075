var mzssMobileNav = mzssMobileNav || {};

(function($, nav) {

    'use strict';

    nav.init = function($elem) {
        this.$elem = $elem;
    };

    nav.open = function() {
        this.$elem.addClass('mobile-nav--open');
        $('body')
            .addClass('mobile-nav-open')
            .removeClass('mobile-nav-closed');
        this.onOpen();
    }

    nav.close = function() {
        this.$elem.removeClass('mobile-nav--open');
        $('body')
            .removeClass('mobile-nav-open')
            .addClass('mobile-nav-closed');
        this.onClose();
    }

    nav.toggle = function() {
        if(this.isOpen()) {
            this.close();
        } else {
            this.open();
        }
    }

    nav.onOpen = function() { return; }
    nav.onClose = function() { return; }

    nav.isOpen = function() {
        return this.$elem.hasClass('mobile-nav--open');
    }

    $(document).ready(function() {
        var $menuToggleBtn = $('#mobileNavToggle');
        var $mobileMenu = $('#mobileNav');

        var mobileNav = Object.create(nav);
        mobileNav.init($mobileMenu);

        mobileNav.onOpen = function() {
            $menuToggleBtn .addClass('burger-button--close');
        }
        mobileNav.onClose = function() {
            $menuToggleBtn.removeClass('burger-button--close');
        }

        $menuToggleBtn.on('click', mobileNav.toggle.bind(mobileNav));
    });

    return nav;
})(jQuery, mzssMobileNav);