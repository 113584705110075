(function($) {
    'use strict';
    
    $(document).ready(function() {
        var $html = $('html, body');
        var $backToTopBtn = $('.back-to-top');

        $(window).on('scroll', function(e) {
            var scrollTop = $('html,body').scrollTop();
            var windowHeight = window.innerHeight;
            var isVisible = $backToTopBtn.hasClass('back-to-top--visible');

            if(scrollTop > windowHeight / 2 && !isVisible) {
                $backToTopBtn.addClass('back-to-top--visible');
            }
            
            if(scrollTop <= windowHeight / 2 && isVisible) {
                $backToTopBtn.removeClass('back-to-top--visible');
            }
        });

        $backToTopBtn.on('click', function(e) {
            e.preventDefault();

            $html.animate({
                scrollTop: 0
            }, 500);
        });
    });
})(jQuery);