(function($) {
    'use strict';
    
    $(document).ready(function() {
        var $mainNav = $('.main-navbar');
        var $menuAnchors = $('a[href^="#"]');
    
        $menuAnchors.on('click', function(e) {
            smoothNav(e);
        });
    
        // $(window).on('hashchange', function() {
        //     var currentHash = window.location.hash;
    
        //     $menuAnchors
        //         .parent()
        //         .removeClass('active');
    
        //     $mainNav.find('a[href="' + currentHash + '"]')
        //         .parent()
        //         .addClass('active');
        // });
    
        function smoothNav(event, duration) {
            event.preventDefault();
    
            var duration = duration || 1000;
            var targetHref = $(event.target).attr('href');
            var $target = $(targetHref);
    
            $('body,html').animate({
                scrollTop: $target.offset().top
            }, duration);
    
            window.location.hash = targetHref;
        }
    });
})(jQuery);