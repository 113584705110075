(function($) {
    'use strict';

    $(document).ready(function() {
        var $heroCarousel = $('#mainHeroCarousel');
        
        var carouselConfig = {
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000
        };

        $heroCarousel.slick(carouselConfig);
    });


})(jQuery);