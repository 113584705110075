(function($) {
    'use strict';
    
    $(document).ready(function() {
        var $galleryImage = $('.gallery-image-box--lightbox');

        $galleryImage.swipebox({
            hideBarsDelay: false
        });
    });
})(jQuery);