(function($) {

    'use strict';

    var createStickyHeader = function($originalHeader) {

        var _matchCellsWidth = function() {
            var headTdWidths = [];
            $originalHeader.find('td').each(function() {
                headTdWidths.push($(this).outerWidth());
            });

            $stickyHeader.find('td').each(function(index) {
                $(this).css({
                    width: headTdWidths[index]
                });
            });
        };

        var _style = function() {
            $stickyHeader.css({
                position: 'fixed',
                top: 0,
                width: $originalHeader.outerWidth(),
                'z-index': 0
            });
        };

        var show = function() {
            if ($stickyHeader.not(':visible')) {
                $stickyHeader.show();
            }
        };

        var hide = function() {
            if ($stickyHeader.is(':visible')) {
                $stickyHeader.hide();
            }
        };


        var $stickyHeader = $originalHeader.clone();
        $originalHeader.after($stickyHeader);
        $stickyHeader.hide();

        _style();
        _matchCellsWidth();

        return {
            $elem: $stickyHeader,
            show: show,
            hide: hide
        };
    };

    $(document).ready(function() {

        var $html = $('html, body');
        var $table = $('.events-table');
        var $head = $('.events-table__head--sticky');


        /**
         * Sticky table header
         */
        if ($table.length && $head.length) {
            var breakpointTop = $head.offset().top;
            var breakpointBottom = breakpointTop + $table.outerHeight() - $head.outerHeight();
            var stickyHeader = createStickyHeader($head);

            $(window).on('scroll', function(e) {
                var scrollTop = $html.scrollTop();

                if (scrollTop >= breakpointTop && scrollTop < breakpointBottom) {
                    stickyHeader.show();
                } else {
                    stickyHeader.hide();
                }
            });
        }

        /**
         * Table filtering
         */
        // Case insensitive jQuery :contains
        $.expr[':'].icontains = $.expr.createPseudo(function(text) {
            return function(e) {
                return $(e).text().toUpperCase().indexOf(text.toUpperCase()) >= 0;
            };
        });

        $('#eventsTableFilter').on('keyup', function() {
            var filterVal = $(this).val();
            $table
                .find('tbody tr:not(.filter-ignore)')
                .hide()
                .filter(':icontains(\'' + filterVal + '\')')
                .show();
        });
    });
})(jQuery);