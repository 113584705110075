(function($) {
    var $fixedInfo = $('.fixed-info');
    var $fixedInfoClose = $fixedInfo.find('.fixed-info__close');

    var CLOSED_KEY = 'fixedInfoIsClosed';
    var isClosed = sessionStorage.getItem(CLOSED_KEY) ? true : false;


    if(isClosed) {
        $fixedInfo.remove();
    } else {
        $fixedInfo.on('click', function() {
            $fixedInfo.remove();
            sessionStorage.setItem(CLOSED_KEY, true);
        });
    }
})(jQuery);