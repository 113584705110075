(function($) {
    'use strict';
    
    $(document).ready(function() {
        var carouselConfig = {
            arrows: false,
            autoplay: true,
            autoplaySpeed: 1000,
            centerMode: true,
            cssEase: 'ease-out',
            dots: false,
            dotsClass: 'partners-carousel__dots-nav',
            infinite: true,
            mobileFirst: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 1000,
            responsive: [{
                    breakpoint: 500,
                    settings: {
                        autoplaySpeed: 1500,
                        slidesToScroll: 2,
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        autoplaySpeed: 2000,
                        centerMode: false,
                        slidesToScroll: 3,
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        autoplaySpeed: 3500,
                        centerMode: false,
                        dots: true,
                        slidesToScroll: 5,
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 1279,
                    settings: {
                        autoplaySpeed: 3500,
                        centerMode: false,
                        dots: true,
                        slidesToScroll: 6,
                        slidesToShow: 6
                    }
                }
            ]
        }
    
        $('.partners-carousel').slick(carouselConfig);
        $('.partners-carousel__item').matchHeight();
    });
})(jQuery);